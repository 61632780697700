import React, { useEffect } from 'react';
import { Hero } from '../components/Hero/Hero';
import { useNavigate, useLocation } from 'react-router-dom';
import { ForWhoSection } from './ForWho/ForWhoSection';
import { Testimonials } from './Testimonials/Testimonials';

export const Masterclass = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const magnes = searchParams.get('magnes');

  useEffect(() => {
    const button = document.querySelector('.cta-button');

    const addWobble = () => {
      button.classList.add('wobble');
      setTimeout(() => {
        button.classList.remove('wobble');
      }, 500);
    };

    // Wobble every 3 seconds
    const intervalId = setInterval(addWobble, 3000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const calculateDiscountedPrice = (originalPrice) => {
    return (originalPrice * 0.6).toFixed(2); // 40% off
  };

  const masterclassPrice = 555;
  const masterclassPlusPrice = 888;
  const promo = !!magnes;

  const masterclassButton = (
    <button
      className="cta-button"
      style={{ marginBottom: '20px !important' }}
      onClick={() => {
        document.querySelector('.pricing-section').scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      TAK, JESTEM GOTOWA!
    </button>
  );

  const handleCheckout = (isPlus = false) => {
    const checkoutPath = '/masterclass/checkout';
    const params = new URLSearchParams();

    if (magnes) {
      params.set('magnes', magnes);
    }

    if (isPlus) {
      params.set('plus', 'true');
    }

    const queryString = params.toString();
    const url = queryString ? `${checkoutPath}?${queryString}` : checkoutPath;

    navigate(url);
  };

  return (
    <>
      <Hero
        title="Sekrety|Kobiecego|Magnetyzmu"
        tag="Premiera 4 stycznia 2025"
        button={masterclassButton}
      />

      <div className="info-section">
        <div className="accent-text" style={{ fontSize: '45px' }}>
          Odkryj sekretny wymiar...
        </div>

        <div className="main-heading" style={{ fontSize: '31px' }}>
          PEWNOŚCI SIEBIE I<br /> KOBIECEGO
          <br /> MAGNETYZMU
        </div>

        <div className="regular-text">Odważ się sięgać po więcej...</div>
        <br />
        <div className="regular-text">
          I PRZYCIĄGAĆ mężczyzn, <br className="xs-break" /> którzy będą Cię
          <br />
          <span className="strong">ROZPIESZCZAĆ I ADOROWAĆ</span>
          <br /> na absolutnie nowym poziomie,
          <br /> jakiego jeszcze nie znałaś!
        </div>
        <br />

        <div className="regular-text">
          To czas, byś zanurzyła się w <br />
          swojej <span className="strong">KOBIECOŚCI...</span>
        </div>
        <br />
        <div className="regular-text">
          i zaczęła dostawać wszystko,
          <br /> czego pragniesz!
        </div>

        <div className="accent-text">Jesteś gotowa?</div>

        <div className="regular-text">
          Mężczyźni czują, jaką energią emanujesz
          <br />
          <br /> Tutaj nie ma miejsca na <br />
          <span style={{ fontStyle: 'italic' }}>
            „fake it till you make it”!
          </span>
          <br />
          <br /> Otwórz się na nowy wymiar
          <br />
          <span className="strong"> MAGNETYCZNEJ KOBIECOŚCI </span>
          <br />
          dzięki której staniesz się kobietą, <br />
          obok której nie da się przejść obojętnie!
        </div>
        <div className="regular-text">
          <br /> Z lekkością, przyjemnością i radością
        </div>
        <div className="regular-text">
          <br />
          Tak! To możliwe! <br />
          <br /> Pokażę Ci, jak...
        </div>
        <br />
      </div>

      <div className="bg-section">
        <div className="accent-text">Twoja Transformacja</div>

        <div className="main-heading" style={{ marginBottom: '10px' }}>
          Od niepewności do <br />
          magnetycznej kobiecości
        </div>

        <div className="benefit-group">
          <div className="divider">
            <span>✿</span>
          </div>
          <div className="regular-text">
            Poczuć nowy powiew swojej{' '}
            <span className="strong">KOBIECEJ MOCY</span>, dzięki której
            poczujesz się absolutnie nie do zatrzymania
            <br />
            <br />
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>
        <div className="benefit-group">
          <div className="regular-text">
            Przyciągać mężczyzn gotowych na pełne zaangażowanie, którzy po
            pierwszej fazie adoracji nie będą się oddalać
            <br />
            <br />
          </div>

          <div className="divider">
            <span>✿</span>
          </div>
        </div>
        <div className="benefit-group">
          <div className="regular-text">
            Tworzyć relacje, na poziomie jakiego jeszcze nie znałaś, w których
            poczujesz się <span className="strong">BEZPIECZNIE</span> i
            zamienisz ciągłą walkę o <span className="strong">NIEGO</span>, o{' '}
            <span className="strong">RELACJĘ</span> na lekkość i zabawę
            <br />
            <br />
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Czuć głęboki spokój i wewnętrzną pewność siebie, zamieniając
            poczucie bycia niewystarczającą na
            <br />
            <span className="strong">Fuck it I'm worth it!</span>
            <br />
            <br />
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Stać się kobietą, która <span className="strong">PRZYCIĄGA</span>{' '}
            spojrzenia wszystkich wokół. Patrzysz na nią i choć trudno Ci to
            wyrazić słowami, czujesz, że emanuje od niej{' '}
            <span className="strong">MAGNETYZM</span>, który nie pozwala Ci
            oderwać od niej wzroku
            <br />
            <br />
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Dołączyć do niewielkiego odsetka{' '}
            <span className="strong">MAGNETYCZNYCH</span> kobiet, które
            przyciągają zaangażowanych mężczyzn, którzy je{' '}
            <span className="strong">ADORUJĄ</span>,{' '}
            <span className="strong">ROZPIESZCZAJĄ</span> i{' '}
            <span className="strong">SPEŁNIAJĄ</span> ich marzenia
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Nauczysz się tworzyć relację, w której
            <br /> pozwolisz mężczyźnie zaopiekować się Tobą czując dogłębne{' '}
            <span className="strong">bezpieczeństwo</span> <br />
            wiedząc, że on pragnie tylko Ciebie
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Przypomnisz sobie, że <span className="strong">ZASŁUGUJESZ</span>
            <br />
            na wszytko co najlepsze i z lekkością
            <br /> otworzysz się na otrzymywanie
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Odzyskasz <span className="strong">RÓWNOWAGĘ </span>
            tworząc związek,
            <br /> w którym, nie musisz walczyć o uwagę, <br />
            wszystko dzieje się
            <span className="strong"> NATURALNIE</span> i w{' '}
            <span className="strong">HARMONII</span>
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Odkryjesz, jak komunikować się w <br />
            <span className="strong">ŻEŃSKIEJ ENERGII</span> <br />
            tak, aby on sam chciał spełniać
            <br /> wszystkie Twoje potrzeby
            <br /> bez Twojego proszenia
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Nauczysz się jak <span className="strong">INSPIROWAĆ</span> <br />{' '}
            mężczyznę aby pragnął, <br />
            tworzyć z Tobą <br />
            przyszłość, o której marzyłaś
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>
      </div>

      <div className="transformation-benefits">
        <div className="main-heading">DLACZEGO TWORZĘ</div>
        <div className="accent-text"> ten Masterclass</div>

        <div className="regular-text">
          Kiedy 4,5 roku temu, rozstałam się z <br />
          partnerem po miesiącach
          <br /> WALKI o nasz związek...
          <br /> nie wiedziałam, że to będzie
          <br />
          <span className="strong">POCZĄTEK ZMIANY,</span> która na zawsze
          <br />
          odmieni mnie jako KOBIETĘ...
          <br />
          <br /> Poznałam wtedy swoją mentorkę, <br /> która pokazała mi, jak
          <span className="strong"> OBUDZIĆ,</span> <br />
          potężną kobiecą moc, <br /> którą MIAŁAM już w sobie
          <br />
          <br /> Odkryłam <span className="strong">SEKRETY magnetyzmu</span>
          <br /> które sprawiły, że stałam
          <br /> się nieodparta dla mężczyzn <br /> <br />I wtedy – pojawił się
          ON...
          <br /> <br />
          Moja ROZBUDZONA KOBIECOŚĆ sprawiła, że
          <br />
          <span className="strong">OSZALAŁ</span> na moim punkcie <br />
          Zaczął mnie adorować, rozpieszczać <br />i spełniać moje marzenia….
          <br />
          <br />
          Dziś jesteśmy po ślubie - na Hawajach,
          <br /> w miejscu, które było moim{' '}
          <span className="strong">MARZENIEM</span> <br />
          od kiedy byłam małą dziewczynką!
          <br />
          <br />
          Nauczenie się tej <span className="strong">JEDNEJ rzeczy</span>{' '}
          sprawiło
          <br /> ze moje życie zmieniło się
          <br />
          <br /> nie do poznania...
          <br /> <br />
          Tymi <span className="strong">MAGNETYCZNYMI </span>zasadami
          <br /> i mindsetem, który za tym stoi, <br />
          podzielę się z Tobą na tym masterclassie
        </div>
        <div
          className="accent-text"
          style={{
            margin: '30px auto',
            maxWidth: '800px',
            textAlign: 'right',
          }}
        >
          - Kate Jaskólska
        </div>
      </div>

      <ForWhoSection />

      <Testimonials />

      <div className="pricing">
        <div className="main-heading">Wybierz swój pakiet</div>
        <div className="pricing-section">
          <div className="pricing-box">
            <div className="pricing-header">
              <div className="accent-text">Masterclass</div>
            </div>

            <div className="price-container">
              <div className="price-column">
                {promo ? (
                  <>
                    <div className="future-price">{masterclassPrice} ZŁ</div>
                    <div className="current-price highlight">
                      {calculateDiscountedPrice(masterclassPrice)} ZŁ
                    </div>
                    <div className="savings-label">Oszczędzasz 40%</div>
                  </>
                ) : (
                  <div className="current-price">{masterclassPrice} ZŁ</div>
                )}
              </div>
            </div>

            <div className="promo-banner">
              <span className="promo-icon">⭐</span>
              <br />
              <br />
            </div>

            <ul className="pricing-features">
              <li>
                💎 3-godzinne nagranie pełne ŻEŃSKIEJ ENERGII, które poprowadzi
                Cię przez głęboką transformację krok po kroku
                <br />
                <small>(+ dożywotni dostęp)</small>
              </li>
              <li>
                💋 Dostęp do zamkniętej grupy na Facebook, NAJLEPSZE towarzystwo
                najbardziej MAGNETYCZNYCH kobiet jakie spotkasz w Internecie
                (serio, Kobiety w Mocy to ZŁOTO warte każdych pieniędzy!)
              </li>
              <li>
                🎁 PREZENT! wizualizacja wymarzonego związku z sesją embodiment
                o wartości 222 zł
              </li>
            </ul>
            <button className="pricing-cta" onClick={() => handleCheckout()}>
              TAK, CHCĘ ZMIENIĆ SWOJE ŻYCIE!
            </button>
          </div>
          <div className="pricing-box">
            <div className="pricing-header">
              <div className="accent-text">Masterclass + Konsultacja</div>
            </div>

            <div className="price-container">
              <div className="price-column">
                {promo ? (
                  <>
                    <div className="future-price">
                      {masterclassPlusPrice} ZŁ
                    </div>
                    <div className="current-price highlight">
                      {calculateDiscountedPrice(masterclassPlusPrice)} ZŁ
                    </div>
                    <div className="savings-label">Oszczędzasz 40%</div>
                  </>
                ) : (
                  <div className="current-price">{masterclassPlusPrice} ZŁ</div>
                )}
              </div>
            </div>

            <div className="promo-banner">
              <span className="promo-icon">⭐</span>
              <br />
              <br />
            </div>

            <ul className="pricing-features">
              <li>
                💎 3-godzinne nagranie pełne ŻEŃSKIEJ ENERGII, które poprowadzi
                Cię przez głęboką transformację krok po kroku
                <br />
                <small>(+ dożywotni dostęp)</small>
              </li>
              <li>
                💋 Dostęp do zamkniętej grupy na Facebook, NAJLEPSZE towarzystwo
                najbardziej MAGNETYCZNYCH kobiet jakie spotkasz w Internecie
                (serio, Kobiety w Mocy to ZŁOTO warte każdych pieniędzy!)
              </li>
              <li>
                🎁 PREZENT! wizualizacja wymarzonego związku z sesją embodiment
                o wartości 222 zł
              </li>
              <li>
                90 min sesja 1:1 magnetyczne uzupełnienie Twojej głębokiej
                transformacji, która pomoże Ci odważyć się sięgać po jeszcze
                więcej
              </li>
            </ul>
            <button
              className="pricing-cta"
              onClick={() => handleCheckout(true)}
            >
              TAK, JESTEM GOTOWA NA JESZCZE GŁĘBSZĄ TRANSFORMACJĘ!
            </button>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center', padding: '20px' }}>
        <a
          href="/masterclass/returns"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          Polityka zwrotów
        </a>
      </div>
    </>
  );
};
