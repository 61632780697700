import React, { useState, useEffect, useCallback } from 'react';
import './countdown.css';

export const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft, targetDate]);

  return (
    <div className="countdown">
      <div className="time">
        <span>{timeLeft.hours}</span> Godzin
      </div>
      <div className="time">
        <span>{timeLeft.minutes}</span> Minut
      </div>
      <div className="time">
        <span>{timeLeft.seconds}</span> Sekund
      </div>
    </div>
  );
};
