import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { validateEmail } from '../utils/validateEmail';
import { Countdown } from '../Countdown/Countdown';

export default function CheckoutForm({ promoEnding, isPromoValid, price }) {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const plus = searchParams.get('plus');
  const magnes = searchParams.get('magnes');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isValid, errorMessage } = validateEmail(email);
    setEmailError(errorMessage);

    if (!isValid) {
      const emailInput = document.getElementById('email');
      emailInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
      emailInput.focus();
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://katejaskolska.pl/masterclass/complete',
        receipt_email: email,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      setTimeout(() => {
        const messageElement = document.getElementById('payment-message');
        if (messageElement) {
          messageElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    } else {
      setMessage(
        'Wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie.'
      );
      setTimeout(() => {
        const messageElement = document.getElementById('payment-message');
        if (messageElement) {
          messageElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    }

    setIsLoading(false);
  };

  const options = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
    paymentMethodOrder: [
      'apple_pay',
      'google_pay',
      'card',
      'blik',
      'p24',
      'klarna',
    ],
    fields: {
      billingDetails: {
        email: 'never',
      },
    },
  };

  const regularPrice = isPromoValid ? price / 0.6 : price;
  const vatRate = 0.23;
  const vat = ((price * vatRate) / (1 + vatRate)).toFixed(2);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {isPromoValid && (
        <>
          <h2 className="promo-ending-text">
            Twój magnetyczny rabat kończy się za:
          </h2>
          <Countdown targetDate={promoEnding} />
        </>
      )}

      <div className="product-details">
        <div className="product-box">
          <h3 style={{ textAlign: 'center' }}>
            Masterclass <br /> SEKRETY KOBIECEGO MAGNETYZMU{' '}
            {!!plus && ' + KONSULTACJA'}
          </h3>
          <div className="price-details">
            {isPromoValid && (
              <div className="price-row discount-info">
                <span>Cena w przedsprzedaży</span>
                <span style={{ textDecoration: 'line-through', color: '#888' }}>
                  {regularPrice.toFixed(2)} PLN
                </span>
              </div>
            )}
            <div className="price-row">
              <span>
                {isPromoValid ? (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    Twoja cena ze zniżką
                    <span
                      style={{
                        background: '#d67688',
                        color: 'white',
                        padding: '2px 8px',
                        borderRadius: '12px',
                        fontSize: '0.9em',
                      }}
                    >
                      -40%
                    </span>
                  </span>
                ) : (
                  'Cena'
                )}
              </span>
              <span
                style={{
                  color: isPromoValid ? '#d67688' : 'inherit',
                  fontWeight: isPromoValid ? '600' : 'inherit',
                }}
              >
                {price.toFixed(2)} PLN
              </span>
            </div>
            <div className="price-row vat-info">
              <span>W tym VAT (23%)</span>
              <span>{vat} PLN</span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-field-container">
          <label
            htmlFor="email"
            style={{
              display: 'block',
              color: 'rgb(48, 49, 61)',
              fontFamily:
                '-apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
              fontSize: '14.88px',
              lineHeight: '17.112px',
              marginBottom: '4px',
            }}
          >
            E-mail{' '}
            <span
              style={{
                color: 'rgba(223, 27, 65, 0.6)',
                fontSize: '13px',
                fontWeight: '400',
              }}
            >
              *
            </span>
          </label>
          <input
            id="email"
            type="email"
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            autoCorrect="off"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            onBlur={(e) => {
              const { errorMessage } = validateEmail(e.target.value);
              setEmailError(errorMessage);
            }}
            placeholder="twoj@email.com"
            style={{
              display: 'block',
              width: '100%',
              padding: '11px 12px 11px 12px',
              border: `1px solid ${emailError ? '#df1b41' : '#e0e0e0'}`,
              borderRadius: '4px',
              backgroundColor: '#ffffff',
              boxSizing: 'border-box',
              boxShadow:
                '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
              fontSize: '16px',
              margin: '4px 0',
            }}
            className={emailError ? 'stripe-input-error' : ''}
          />
          {emailError && <div id="email-message">{emailError}</div>}
        </div>
      </div>

      <PaymentElement id="payment-element" options={options} />
      <button
        id="submit"
        className="cta-button"
        style={{ width: '100%', marginTop: 0 }}
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'ZAPŁAĆ'}
        </span>
      </button>
      {message && (
        <div id="payment-message" style={{ color: '#df1b41' }}>
          {message}
        </div>
      )}
    </form>
  );
}
